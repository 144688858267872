<script setup>
import Pagination from "@/components/Pagination.vue";
import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-md-2">
        <default-info-card
          :icon="{
            component: 'fas fa-landmark',
            background: 'bg-gradient-success',
          }"
          title="Balance"
          description="Available Balance till date"
          :value="availableBalance"
          hide="true"
        />
      </div>
      <div class="col-10">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h5 class="card-title">Filter</h5>
              <div class="d-flex">
                <router-link
                  class="btn btn-sm btn-primary me-2"
                  :to="{ name: 'AddExpense' }"
                >
                  <i class="fa fa-plus" aria-hidden="true"></i> Create Entry
                </router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label for="example-text-input" class="form-control-label"
                  >Name</label
                >
                <div class="input-group">
                  <span class="input-group-text text-body">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    v-model="filterReq.search"
                    type="text"
                    class="form-control"
                    placeholder="Search product name..."
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="exampleFormControlSelect1"
                    >Transaction Type</label
                  >
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="filterReq.transactionType"
                  >
                    <option value="">-Choose Transaction-</option>
                    <option value="cr">Credit (Cr.)</option>
                    <option value="dr">Debit (Dr.)</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="exampleFormControlSelect1">Select Tag</label>
                  <select
                    v-model="filterReq.tag"
                    class="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <option value="">-Choose Tag-</option>
                    <option v-for="el of Tag" :key="el" :value="el">
                      {{ el }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label for="example-text-input" class="form-control-label"
                  >From Date</label
                >
                <div class="input-group">
                  <span class="input-group-text text-body">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    v-model="filterReq.fromDate"
                    type="date"
                    class="form-control"
                    placeholder="Search product name..."
                  />
                </div>
              </div>
              <div class="col-md-4">
                <label for="example-text-input" class="form-control-label"
                  >To Date</label
                >
                <div class="input-group">
                  <span class="input-group-text text-body">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    v-model="filterReq.toDate"
                    type="date"
                    class="form-control"
                    placeholder="Search product name..."
                  />
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-5">
                <button
                  @click="filter"
                  class="btn btn-info btn-sm ms-auto me-2"
                >
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
                <button
                  @click="resetFilter"
                  class="btn btn-warning btn-sm ms-auto"
                >
                  <i class="fas fa-undo"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />

    <BalanceSheetTableComponent
      heading="Balance Sheet"
      :headers="tableHead"
      :data="balanceSheet"
      button="Add Product"
      @delete-record="deleteRecord"
    />
  </div>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="d-flex justify-content-center">
        <Pagination
          :totalPages="totalPages"
          :currentPage="currentPage"
          @page-changed="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Tag } from "@/constants/constants";
import BalanceSheetTableComponent from "./components/BalanceSheetTableComponent.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    BalanceSheetTableComponent,
  },
  data() {
    const filterReq = {
      pageNo: 1,
      limit: 10,
      transactionType: "",
      search: "",
      tag: "",
      fromDate: "",
      toDate: "",
    };
    return {
      filterReq,
      tableHead: [
        { id: 1, name: "Info" },
        { id: 2, name: "Trx. Type", align: "center" },
        { id: 3, name: "Qty" },
        { id: 4, name: "Amount" },
        { id: 5, name: "Balance", align: "center" },
        { id: 6, name: "Tag", align: "center" },
        { id: 7, name: "Date", align: "center" },
      ],
    };
  },
  methods: {
    ...mapActions(["fetchBalanceSheet"]),
    async getBalanceSheet(params) {
      try {
        await this.fetchBalanceSheet(params);
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    handlePageChange(page) {
      this.filterReq.pageNo = page;
      this.getBalanceSheet(this.filterReq);
    },
    filter() {
      this.filterReq.pageNo = 1;
      this.getBalanceSheet(this.filterReq);
    },
    resetFilter() {
      this.filterReq.search = "";
      this.filterReq.tag = "";
      this.filterReq.transactionType = "";
      this.filterReq.pageNo = 1;
      this.filterReq.limit = 10;
      this.filterReq.fromDate = "";
      this.filterReq.toDate = "";
      this.getBalanceSheet(this.filterReq);
    },
  },

  created() {
    this.getBalanceSheet({ pageNo: 1, limit: 10 });
  },

  computed: {
    ...mapGetters([
      "totalPages",
      "currentPage",
      "balanceSheet",
      "availableBalance",
    ]),
  },
};
</script>
<style scoped>
.c-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-group {
  display: flex;
}
</style>
