import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Categories from "../views/Categories.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import store from "@/store";
import Brand from "../views/Brand.vue";
import Warehouse from "../views/Warehouse.vue";
import Product from "../views/Product.vue";
import Shops from "../views/Shops.vue";
import ShopDetail from "../views/ShopDetail.vue";
import ImportProduct from "../views/ImportProduct.vue";
import AddSell from "../views/AddSell.vue";
import Sales from "../views/Sales.vue";
import BalanceSheet from "../views/BalanceSheet.vue";
import AddExpense from "../views/AddExpense.vue";
import History from "../views/History.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/signin",
    meta: { requiresAuth: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/categories",
    name: "Categories",
    component: Categories,
    meta: { requiresAuth: true },
  },
  {
    path: "/brands",
    name: "Brands",
    component: Brand,
    meta: { requiresAuth: true },
  },
  {
    path: "/warehouse",
    name: "Warehouse",
    component: Warehouse,
    meta: { requiresAuth: true },
  },
  {
    path: "/shops",
    name: "Shop",
    component: Shops,
    meta: { requiresAuth: true },
  },
  {
    path: "/shops/:id",
    name: "ShopDetail",
    component: ShopDetail,
    meta: { requiresAuth: true },
  },
  {
    path: "/shops/:id/import-product",
    name: "ImportProduct",
    component: ImportProduct,
    meta: { requiresAuth: true },
  },
  {
    path: "/add-product",
    name: "AddProduct",
    component: Product,
    meta: { requiresAuth: true },
  },

  {
    path: "/sales",
    name: "Sales",
    component: Sales,
    meta: { requiresAuth: true },
  },
  {
    path: "/sales/add-sale",
    name: "AddSale",
    component: AddSell,
    meta: { requiresAuth: true },
  },
  {
    path: "/balance-sheet",
    name: "BalanceSheet",
    component: BalanceSheet,
    meta: { requiresAuth: true },
  },
  {
    path: "/balance-sheet/add-entry",
    name: "AddExpense",
    component: AddExpense,
    meta: { requiresAuth: true },
  },
  {
    path: "/history",
    name: "History",
    component: History,
    meta: { requiresAuth: true },
  },

  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next({ name: "Dashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },

  {
    path: "/:pathMatch(.*)*", // This is the catch-all route
    redirect: "/signin",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next({ name: "Signin" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
