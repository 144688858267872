<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>{{ heading }}</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                :class="header.align ? 'text-center' : 'text-left'"
                v-for="header in headers"
                :key="header.id"
              >
                {{ header.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr :key="item.id" v-for="item in data">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <img
                      :src="item.image"
                      class="avatar avatar-sm me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ item.name }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ item.category.name }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item.packing }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ item.MRP }}
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="badge badge-sm bg-gradient-success">{{
                  item.status
                }}</span>
              </td>

              <!-- <td class="align-middle text-center text-sm">
                <a
                  href="javascript:;"
                  class="text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Edit user"
                  >Edit</a
                >
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: String,
    headers: Array,
    data: Array,
  },
};
</script>
