<script setup>
defineProps({
  label: {
    type: String,
    default: "",
  },
  active: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  prev: {
    type: Boolean,
    default: false,
  },
  next: {
    type: Boolean,
    default: false,
  },
  pageNo: {
    type: Number,
    default: 2,
  },
});
const getClasses = (active, disabled) => {
  let activeValue, disabledValue;

  activeValue = active ? "active" : null;
  disabledValue = disabled ? "disabled" : null;

  return `${activeValue} ${disabledValue}`;
};
</script>
<template>
  <li class="page-item" :class="getClasses(active, disabled)">
    <a class="page-link" href="javascript:;" @click="onPageClick">
      {{ prev || next ? null : label }}
      <i v-if="prev" class="fa fa-angle-left"></i>
      <i v-if="next" class="fa fa-angle-right"></i>
    </a>
  </li>
</template>

<script>
export default {
  methods: {
    onPageClick() {
      if (!this.disabled) {
        let page;
        if (this.prev) {
          page = this.pageNo - 1;
        } else if (this.next) {
          page = this.pageNo + 1;
        } else {
          page = Number(this.label);
        }
        this.$emit("page-click", page);
      }
    },
  },
};
</script>
