<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header pb-0">
          <div class="d-flex align-items-center">
            <p class="mb-0">Import Product</p>
          </div>
        </div>
        <div class="card-body">
          <p class="text-uppercase text-sm">
            Import product from warehouse to Shop
          </p>
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="selectProduct"
                    >Select Product From Warehouse</label
                  >
                  <select
                    @change="handleProductChange"
                    class="form-control"
                    id="selectProduct"
                    :class="{ 'is-invalid': formError.product }"
                  >
                    <option>-Choose Product-</option>
                    <option
                      v-for="product of products"
                      :key="product._id"
                      :value="product._id"
                    >
                      {{ product.name }} ({{ product.packing }}),
                      {{ product.MRP }}rs
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ formError?.product }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="Quantity" class="form-control-label"
                    >Qty in Box (In Cases)</label
                  >
                  <input
                    id="Quantity"
                    class="form-control"
                    type="number"
                    v-model="product.boxQty"
                    :class="{ 'is-invalid': formError.boxQty }"
                  />
                  <small class="text-sm-left text-info">{{
                    product.availableQtnInBoxMsg
                  }}</small>
                  <div class="invalid-feedback">{{ formError?.boxQty }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="QuantityInLoose" class="form-control-label"
                    >Qty in Loose (In Btls)</label
                  >
                  <input
                    id="QuantityInLoose"
                    class="form-control"
                    type="number"
                    v-model="product.looseQty"
                    :class="{ 'is-invalid': formError.looseQty }"
                  />
                  <small class="text-sm-left text-info">{{
                    product.availableQtnInLooseMsg
                  }}</small>
                  <div class="invalid-feedback">{{ formError?.looseQty }}</div>
                </div>
              </div>
            </div>
            <br />
            <argon-button
              type="submit"
              color="primary"
              size="sm"
              class="ms-auto me-2"
              >Import</argon-button
            >
            <argon-button
              @click="cancel"
              type="button"
              color="danger"
              size="sm"
              class="ms-auto"
              >Cancel</argon-button
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    const formError = {};
    const availableQtnInBox = 0;
    const availableQtnInLoose = 0;
    return {
      product: {
        productId: "",
        boxQty: "",
        looseQty: "",
        availableQtnInBoxMsg: "",
        availableQtnInLooseMsg: "",
      },
      formError,
      availableQtnInBox,
      availableQtnInLoose,
    };
  },
  methods: {
    ...mapActions(["fetchProducts", "importProductAction"]),

    handleProductChange(event) {
      const productId = event.target.value;
      const selectedProduct = this.products.find((product) => {
        if (String(product._id) === String(productId)) {
          return product;
        }
      });
      const availableBox = Math.floor(
        selectedProduct.qty / selectedProduct?.qtyPerBox
      );

      const availableLoose = Math.floor(
        selectedProduct.qty % selectedProduct?.qtyPerBox
      );

      this.availableQtnInBox = availableBox;
      this.availableQtnInLoose = availableLoose;

      this.product = {
        ...this.product,
        productId: selectedProduct?._id,
        boxQty: "",
        looseQty: "",
        qtyPerBox: selectedProduct?.qtyPerBox,
        availableQtnInBoxMsg: `Available ${availableBox || 0} Qty in box`,
        availableQtnInLooseMsg: `Available ${availableLoose || 0} Qty in Loose`,
        warehouseId: localStorage.getItem("warehouseId"),
      };
    },
    async getProducts(params) {
      try {
        return await this.fetchProducts(params);
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    errorHandler() {
      this.formError = {};
      let isSubmit = true;
      for (let field in this.product) {
        if (this.product[field] === "") {
          if (field === "productId") field = "product";
          const fieldName = field
            .replace(/([a-z0-9])([A-Z])/g, "$1-$2")
            .toLowerCase();
          this.formError[field] = `${fieldName} is required`;
          isSubmit = false;
        }
        if (
          field === "boxQty" &&
          this.product[field] > this.availableQtnInBox
        ) {
          const fieldName = field
            .replace(/([a-z0-9])([A-Z])/g, "$1-$2")
            .toLowerCase();
          this.formError[field] =
            `${fieldName} cannot exceed the available quantity`;
          isSubmit = false;
        }
        if (
          field === "looseQty" &&
          this.product[field] > this.availableQtnInLoose
        ) {
          const fieldName = field
            .replace(/([a-z0-9])([A-Z])/g, "$1-$2")
            .toLowerCase();
          this.formError[field] =
            `${fieldName} cannot exceed the available quantity`;
          isSubmit = false;
        }
      }
      isSubmit ? (this.formError = {}) : "";
      return isSubmit;
    },
    async submitForm() {
      if (await this.errorHandler()) {
        const res = await this.importProductAction({
          shopId: this.getShopId,
          productId: this.product.productId,
          boxQty: this.product.boxQty,
          looseQty: this.product.looseQty,
        });
        this.$router.push(`/shops/${this.getShopId}`);
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "success",
          title: res?.data?.message,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    // Redirect to shop-details
    cancel() {
      this.$router.push(`/shops/${this.getShopId}`);
    },
  },

  created() {
    this.fetchProducts({ pageNo: 1, limit: 100 });
  },
  computed: {
    ...mapGetters(["products"]),
    getShopId() {
      return this.$route.params.id;
    },
  },
};
</script>
