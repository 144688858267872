import axiosInstance from "@/axiosInstance";

const state = {
  sales: [],
};

const actions = {
  async addSellAction({ commit }, params) {
    try {
      const response = await axiosInstance.post(`/sales`, params);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchSalesAction({ commit }, params) {
    try {
      const {
        pageNo = 1,
        limit = 10,
        category,
        shop,
        search,
        fromDate,
        toDate,
      } = params;
      let url = `/sales?pageNo=${pageNo}&limit=${limit}`;
      if (category) {
        url = `${url}&category=${category}`;
      }
      if (shop) {
        url = `${url}&shop=${shop}`;
      }
      if (search) {
        url = `${url}&search=${search}`;
      }
      if (fromDate) {
        url = `${url}&fromDate=${fromDate}`;
      }
      if (toDate) {
        url = `${url}&toDate=${toDate}`;
      }
      console.log("url===", url, fromDate, toDate);
      const response = await axiosInstance.get(url, params);
      await commit("setSales", response?.data?.results?.data);
      await commit("setTotalPages", response?.data?.results?.totalPage);
      await commit("setCurrentPage", Number(response?.data?.results?.pageNo));
      return;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async deleteSellAction({ commit }, params) {
    try {
      const response = await axiosInstance.delete(`/sales/${params.id}`);
      return response?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

const mutations = {
  setSales: (state, data) => {
    state.sales = data;
  },
};

const getters = {
  sales: (state) => state.sales,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
