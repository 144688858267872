<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>{{ heading }}</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                :class="header.align ? 'text-center' : 'text-left'"
                v-for="header in headers"
                :key="header.id"
              >
                {{ header.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr :key="item.id" v-for="item in data">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <p class="mb-0 text-sm">{{ item.note }}</p>
                  </div>
                </div>
              </td>
              <td>
                <p
                  class="text-xs text-center mb-0"
                  :class="
                    item?.transactionType == 'dr'
                      ? 'text-danger'
                      : 'text-success'
                  "
                >
                  {{ item.transactionType.toUpperCase() }}
                </p>
              </td>
              <td>
                <p class="text-xs mb-0">
                  {{ item?.qty || "N/A" }}
                </p>
              </td>
              <td>
                <p
                  class="text-xs mb-0"
                  :class="
                    item?.transactionType == 'dr'
                      ? 'text-danger'
                      : 'text-success'
                  "
                >
                  ₹
                  {{ item?.amount }}
                </p>
              </td>
              <td>
                <p class="text-xs text-center mb-0">₹ {{ item.balance }}</p>
              </td>
              <td>
                <p class="text-xs text-center mb-0">
                  {{ item.tag }}
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <p class="text-xs mb-0">
                  {{ formatDate(item?.createdAt, "dd/MM/yyyy") }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/dateUtils";
export default {
  props: {
    heading: String,
    headers: Array,
    data: Array,
  },
  methods: {
    formatDate(date, format) {
      return formatDate(date, format);
    },
    deleteRow(id) {
      this.$emit("delete-record", id);
    },
  },
};
</script>
