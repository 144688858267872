<script setup>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import SaleCategoryList from "./components/SaleCategoryList.vue";
import SALE_IMG from "@/assets/img/icons/sale64x.png";
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              v-if="totalRevenue !== null"
              title="Total Revenue"
              :value="totalRevenue"
              currency="₹"
              description="<span
                class='text-sm font-weight-bolder text-success'
                ></span> Total revenue till date"
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Short Product"
              :value="warehouseShortProduct"
              description="<span
                class='text-sm font-weight-bolder text-danger'
                ></span>In warehouse (<100)"
              :icon="{
                component: 'ni ni-app',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Out of stock"
              :value="shopOutOfStock"
              description="<span
                class='text-sm font-weight-bolder text-danger'
                ></span>Shop's total out of stock"
              :icon="{
                component: 'ni ni-bag-17',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="This Month Sale"
              :value="thisMonthSale"
              currency="₹"
              description="<span
                class='text-sm font-weight-bolder text-success'
                ></span>Total this month sale"
              :icon="{
                component: 'ni ni-cart',
                background: 'bg-gradient-warning',
                shape: 'rounded-circle',
              }"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <gradient-line-chart
                v-if="graphX.length && graphY.length"
                id="chart-line"
                title="Sales Overview of Last 6 Months"
                description="<i class='fa fa-arrow-up text-success'></i>
      <span class='font-weight-bold'>6 Month's</span> Projection"
                :chart="{
                  labels: graphX,
                  datasets: [
                    {
                      label: 'Sale',
                      data: graphY,
                    },
                  ],
                }"
              />
            </div>
          </div>
          <!-- <div class="col-lg-5">
            <carousel />
          </div> -->
        </div>
        <div class="row mt-4">
          <div class="col-lg-7 mb-lg-0 mb-4">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-2">Sales by Shop</h6>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table align-items-center">
                  <tbody>
                    <tr v-for="(sale, index) in salesByShop" :key="index">
                      <td class="w-30">
                        <div class="px-2 py-1 d-flex align-items-center">
                          <div>
                            <img
                              height="24"
                              width="24"
                              :src="SALE_IMG"
                              alt="Country flag"
                            />
                          </div>
                          <div class="ms-4">
                            <p class="mb-0 text-xs font-weight-bold">Shop:</p>
                            <h6 class="mb-0 text-sm">{{ sale.shopName }}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Qty:</p>
                          <h6 class="mb-0 text-sm">{{ sale.totalQty }}</h6>
                        </div>
                      </td>
                      <td class="text-sm align-middle">
                        <div class="text-center col">
                          <p class="mb-0 text-xs font-weight-bold">Discount:</p>
                          <h6 class="mb-0 text-sm">{{ sale.totalDiscount }}</h6>
                        </div>
                      </td>

                      <td>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Sales:</p>
                          <h6 class="mb-0 text-sm">{{ sale.totalSale }}</h6>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <sale-category-list :categories="salesByCategory" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  methods: {
    ...mapActions([
      "fetchSalesByShop",
      "fetchSalesByCategory",
      "fetchSalesByMonth",
    ]),
    async getSalesByShop() {
      try {
        this.fetchSalesByShop();
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    async getSalesByCategory() {
      try {
        this.fetchSalesByCategory();
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    async getSalesByMonth() {
      try {
        this.fetchSalesByMonth();
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
  },
  created() {
    this.getSalesByShop();
    this.getSalesByCategory();
    this.getSalesByMonth();
  },
  // mounted() {
  //   this.getGraphLabels;
  //   this.getGraphData;
  // },
  computed: {
    ...mapGetters([
      "salesByShop",
      "salesByCategory",
      "salesByMonth",
      "graphX",
      "graphY",
      "totalRevenue",
      "warehouseShortProduct",
      "thisMonthSale",
      "shopOutOfStock",
    ]),
  },
};
</script>
