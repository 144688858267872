import axiosInstance from "@/axiosInstance";

const state = {
  salesByShop: [],
  salesByCategory: [],
  salesByMonth: [],
  graphX: [],
  graphY: [],
  graphY: [],
  totalRevenue: null,
  warehouseShortProduct: "",
  thisMonthSale: "",
  shopOutOfStock: "",
};

const actions = {
  async fetchSalesByShop({ commit }) {
    try {
      const response = await axiosInstance.get(`/dashboard/sales-by-shop`);
      await commit("setSalesByShop", response?.data?.results);
      return;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchSalesByCategory({ commit }) {
    try {
      const response = await axiosInstance.get(`/dashboard/sales-by-category`);
      await commit("setSalesByCategory", response?.data?.results);
      return;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchSalesByMonth({ commit }) {
    try {
      const response = await axiosInstance.get(`/dashboard/sales-by-month`);

      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      if (response?.data?.results?.sales) {
        const labels = [];
        const salesAmount = [];

        for (let sale of response?.data?.results?.sales) {
          const monthIndex = Number(sale?.month) - 1;
          labels.push(`${months[monthIndex]} ${sale?.year}`);
          salesAmount.push(sale?.totalSellingPrice);
        }
        await commit("setGraphData", { labels, salesAmount });
      }
      await commit("setCountData", {
        totalRevenue: response?.data?.results?.totalRevenue || 0,
        warehouseShortProducts:
          response?.data?.results?.warehouseShortProducts || 0,
        thisMonthSaleAmount: response?.data?.results?.thisMonthSaleAmount || 0,
        shopOutOfStock: response?.data?.results?.shopOutOfStock || 0,
      });

      return;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

const mutations = {
  setSalesByShop: (state, data) => {
    state.salesByShop = data;
  },
  setSalesByCategory: (state, data) => {
    state.salesByCategory = data;
  },
  setSalesByMonth: (state, data) => {
    state.salesByMonth = data;
  },
  setGraphData: (state, data) => {
    state.graphX = data.labels;
    state.graphY = data.salesAmount;
  },
  setCountData: (state, data) => {
    state.totalRevenue = String(data.totalRevenue);
    state.warehouseShortProduct = String(data.warehouseShortProducts);
    state.thisMonthSale = String(data.thisMonthSaleAmount);
    state.shopOutOfStock = String(data.shopOutOfStock);
  },
};

const getters = {
  salesByShop: (state) => state.salesByShop,
  salesByCategory: (state) => state.salesByCategory,
  salesByMonth: (state) => state.salesByMonth,
  graphX: (state) => state.graphX,
  graphY: (state) => state.graphY,
  totalRevenue: (state) => state.totalRevenue,
  warehouseShortProduct: (state) => state.warehouseShortProduct,
  thisMonthSale: (state) => state.thisMonthSale,
  shopOutOfStock: (state) => state.shopOutOfStock,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
