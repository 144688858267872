<script setup>
import Pagination from "@/components/Pagination.vue";
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h5 class="card-title">Filter</h5>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label for="example-text-input" class="form-control-label"
                  >From Date</label
                >
                <div class="input-group">
                  <span class="input-group-text text-body">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    v-model="filterReq.fromDate"
                    type="date"
                    class="form-control"
                    placeholder="Search product name..."
                  />
                </div>
              </div>
              <div class="col-md-4">
                <label for="example-text-input" class="form-control-label"
                  >To Date</label
                >
                <div class="input-group">
                  <span class="input-group-text text-body">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    v-model="filterReq.toDate"
                    type="date"
                    class="form-control"
                    placeholder="Search product name..."
                  />
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-5">
                <button
                  @click="filter"
                  class="btn btn-info btn-sm ms-auto me-2"
                >
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
                <button
                  @click="resetFilter"
                  class="btn btn-warning btn-sm ms-auto"
                >
                  <i class="fas fa-undo"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <HistoryTableComponent
      heading="History"
      :headers="tableHead"
      :data="history"
    />
  </div>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="d-flex justify-content-center">
        <Pagination
          :totalPages="totalPages"
          :currentPage="currentPage"
          @page-changed="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HistoryTableComponent from "./components/HistoryTableComponent.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    HistoryTableComponent,
  },
  data() {
    const filterReq = {
      pageNo: 1,
      limit: 10,
      fromDate: "",
      toDate: "",
    };
    return {
      filterReq,
      tableHead: [
        { id: 1, name: "Name" },
        { id: 2, name: "Qty", align: "center" },
        { id: 3, name: "Packing", align: "center" },
        { id: 4, name: "MRP", align: "center" },
        { id: 5, name: "From", align: "center" },
        { id: 6, name: "To", align: "center" },
        { id: 7, name: "Date", align: "center" },
      ],
    };
  },
  methods: {
    ...mapActions(["fetchHistory"]),
    async getHistory(params) {
      try {
        await this.fetchHistory(params);
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    handlePageChange(page) {
      this.filterReq.pageNo = page;
      this.getHistory(this.filterReq);
    },
    filter() {
      this.filterReq.pageNo = 1;
      this.getHistory(this.filterReq);
    },
    resetFilter() {
      this.filterReq.search = "";
      this.filterReq.pageNo = 1;
      this.filterReq.limit = 10;
      this.filterReq.fromDate = "";
      this.filterReq.toDate = "";
      this.getHistory(this.filterReq);
    },
  },

  created() {
    this.getHistory({ pageNo: 1, limit: 10 });
  },

  computed: {
    ...mapGetters(["history", "totalPages", "currentPage", "totalProduct"]),
  },
};
</script>
