import axios from "axios";
import store from "@/store";
import router from "./router";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 20000, // Optional: Set a timeout for requests
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Do something before the request is sent
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code within the range of 2xx causes this function to trigger
    return response;
  },
  async (error) => {
    // Any status codes outside the range of 2xx cause this function to trigger
    // Handle errors here, for example by logging them or showing a notification
    console.log("err=====", error?.response?.data);
    const errorMsg =
      error?.response?.data?.errors[0]?.message || "Something went wrong !";

    console.error("API Error:", error);
    if (error.response && error.response.status === 401) {
      // Handle logout
      await store.dispatch("logout");
      router.push({ name: "Signin" });
    }
    return Promise.reject(errorMsg);
  }
);

export default axiosInstance;
