import axiosInstance from "@/axiosInstance";

const state = {
  balanceSheet: [],
  availableBalance: "",
};

const actions = {
  async fetchBalanceSheet({ commit }, params) {
    try {
      const {
        search,
        tag,
        transactionType,
        fromDate,
        toDate,
        pageNo = 1,
        limit = 10,
      } = params;
      let url = `/balance-sheet?pageNo=${pageNo}&limit=${limit}`;

      if (transactionType) url = `${url}&transactionType=${transactionType}`;
      if (search) url = `${url}&search=${search}`;
      if (tag) url = `${url}&tag=${tag}`;
      if (fromDate) url = `${url}&fromDate=${fromDate}`;
      if (toDate) url = `${url}&toDate=${toDate}`;

      const response = await axiosInstance.get(url);
      await commit("setBalanceSheet", response?.data?.results);
      await commit("setTotalPages", response?.data?.results?.sheet?.totalPage);
      await commit(
        "setCurrentPage",
        Number(response?.data?.results?.sheet?.pageNo)
      );
      return;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async createBalanceSheet({ commit }, params) {
    try {
      return await axiosInstance.post("/balance-sheet", params);
    } catch (error) {
      console.log("error from warehouse action=====", error);
      return Promise.reject(error);
    }
  },
};

const mutations = {
  setBalanceSheet: (state, data) => {
    state.balanceSheet = data?.sheet?.data;
    state.availableBalance = data?.availableBalance;
  },
  setTotalPages: (state, totalPages) => {
    state.totalPages = totalPages;
  },
  setCurrentPage: (state, currentPage) => {
    state.currentPage = currentPage;
  },
};

const getters = {
  balanceSheet: (state) => state.balanceSheet,
  availableBalance: (state) => state.availableBalance,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
