import axiosInstance from "@/axiosInstance";

const state = {
  history: [],
};

const actions = {
  async fetchHistory({ commit }, params) {
    try {
      const { fromDate, toDate, pageNo = 1, limit = 10 } = params;
      let url = `/history?pageNo=${pageNo}&limit=${limit}`;

      if (fromDate) url = `${url}&fromDate=${fromDate}`;
      if (toDate) url = `${url}&toDate=${toDate}`;

      const response = await axiosInstance.get(url);
      await commit("setHistory", response?.data?.results?.data);
      await commit("setTotalPages", response?.data?.results?.totalPage);
      await commit("setCurrentPage", Number(response?.data?.results?.pageNo));
      return;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

const mutations = {
  setHistory: (state, data) => {
    state.history = data;
  },
};

const getters = {
  history: (state) => state.history,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
