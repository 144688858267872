<script setup>
import BrandTableComponent from "./components/BrandTableComponent.vue";
import Pagination from "@/components/Pagination.vue";
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h5 class="card-title">Brand Filter</h5>
            </div>

            <div class="row">
              <div class="col-md-5">
                <label for="example-text-input" class="form-control-label"
                  >Name</label
                >
                <div class="input-group">
                  <span class="input-group-text text-body">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    v-model="filterReq.search"
                    class="form-control"
                    placeholder="Search by name..."
                  />
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label for="exampleFormControlSelect1">Select Category</label>
                  <select
                    v-model="filterReq.category"
                    class="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <option value="">-Choose category-</option>
                    <option
                      v-for="category of categories?.data"
                      :key="category._id"
                      :value="category._id"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <button @click="filter" class="btn btn-info btn-sm ms-auto me-2">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <button
                @click="resetFilter"
                class="btn btn-warning btn-sm ms-auto"
              >
                <i class="fas fa-undo"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        <BrandTableComponent
          heading="Brands Table"
          :headers="tableHead"
          :data="brands"
        />
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="d-flex justify-content-center">
          <Pagination
            :totalPages="totalPages"
            :currentPage="currentPage"
            @page-changed="handlePageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    BrandTableComponent,
  },
  data() {
    const filterReq = {
      pageNo: 1,
      limit: 10,
      search: "",
      category: "",
    };
    return {
      tableHead: [
        { id: 1, name: "Name" },
        { id: 2, name: "Category", padding: "ps-2" },
        { id: 2, name: "Packing" },
        { id: 2, name: "Price" },
        { id: 3, name: "Status", align: "center" },
        // { id: 4, name: "Action", align: "center" },
      ],
      filterReq,
    };
  },
  methods: {
    ...mapActions(["fetchBrands", "fetchCategories"]),

    async getBrands(params) {
      try {
        await this.fetchBrands(params);
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    async getCategories(params) {
      try {
        this.fetchCategories(params);
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    handlePageChange(page) {
      this.filterReq.pageNo = page;
      this.getBrands(this.filterReq);
    },

    filter() {
      this.filterReq.pageNo = 1;
      this.getBrands(this.filterReq);
    },
    resetFilter() {
      this.filterReq.search = "";
      this.filterReq.category = "";
      this.filterReq.pageNo = 1;
      this.filterReq.limit = 10;
      this.getBrands(this.filterReq);
    },
  },
  created() {
    this.fetchBrands({});
    this.getCategories({ pagination: false });
  },
  computed: {
    ...mapGetters(["allBrands", "totalPages", "currentPage", "categories"]),
    brands() {
      return this.allBrands;
    },
  },
};
</script>
