<script setup>
import Pagination from "@/components/Pagination.vue";
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <ShopCard
          :shop="shopDetails"
          :total-product="totalProduct"
          :in-stock-product="inStock"
          :out-of-stock-product="outOfStock"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h5 class="card-title">Product Filter</h5>
              <div class="d-flex">
                <router-link
                  class="btn btn-sm btn-success me-2"
                  :to="`/shops/${getShopId}/import-product`"
                >
                  <i class="fa fa-plus" aria-hidden="true"></i> Import Product
                </router-link>
                <!-- <router-link
                  class="btn btn-sm btn-danger ml-4"
                  :to="`/shops/${getShopId}/import-product`"
                >
                  <i class="fa fa-minus" aria-hidden="true"></i> Export Product
                </router-link> -->
              </div>
            </div>

            <div class="row">
              <div class="col-md-5">
                <label for="example-text-input" class="form-control-label"
                  >Name</label
                >
                <div class="input-group">
                  <span class="input-group-text text-body">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    v-model="filterReq.search"
                    class="form-control"
                    placeholder="Search product name..."
                  />
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label for="exampleFormControlSelect1">Select Category</label>
                  <select
                    v-model="filterReq.category"
                    class="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <option value="">-Choose category-</option>
                    <option
                      v-for="category of categories?.data"
                      :key="category._id"
                      :value="category._id"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <button @click="filter" class="btn btn-info btn-sm ms-auto me-2">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <button
                @click="resetFilter"
                class="btn btn-warning btn-sm ms-auto"
              >
                <i class="fas fa-undo"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <ShopProductTableComponent
      heading="Products Table"
      :headers="tableHead"
      :data="shopProducts"
      @delete-record="deleteRecord"
      @sort="sortProduct"
      button="Add Product"
    />
  </div>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="d-flex justify-content-center">
        <Pagination
          :totalPages="totalPages"
          :currentPage="currentPage"
          @page-changed="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ShopCard from "./components/ShopCard.vue";
import ShopProductTableComponent from "./components/ShopProductTableComponent.vue";
import ProductFilterCard from "./components/ProductFilterCard.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ShopCard,
    ShopProductTableComponent,
    ProductFilterCard,
  },
  data() {
    const filterReq = {
      pageNo: 1,
      limit: 10,
      shopId: this.$route.params.id,
      search: "",
      category: "",
      qtySort: 0,
    };

    return {
      tableHead: [
        { id: 1, name: "Name" },
        { id: 2, name: "Category", padding: "ps-2" },
        { id: 3, name: "Packing" },
        { id: 4, name: "Qty", sorting: true },
        { id: 5, name: "Selling Price" },
        { id: 6, name: "Status", align: "center" },
        // { id: 7, name: "Action", align: "center" },
      ],
      filterReq,
    };
  },
  methods: {
    ...mapActions([
      "fetchShopDetails",
      "fetchShopProducts",
      "deleteShopProductAction",
      "fetchCategories",
    ]),

    async getShop() {
      try {
        const id = this.$route.params.id;
        console.log("Fetching shop details for ID:", id);
        await this.fetchShopDetails({ id });
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    async getProducts(params) {
      try {
        console.log("Fetching products with params:", params);
        await this.fetchShopProducts(params);
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    handlePageChange(page) {
      this.filterReq.pageNo = page;
      this.getProducts(filterReq);
    },
    filter() {
      this.filterReq.pageNo = 1;
      this.getProducts(this.filterReq);
    },

    sortProduct(sortOrder) {
      this.filterReq.pageNo = 1;
      this.filterReq.qtySort = sortOrder;
      this.getProducts(this.filterReq);
    },

    resetFilter() {
      this.filterReq.search = "";
      this.filterReq.category = "";
      this.filterReq.pageNo = 1;
      this.filterReq.limit = 10;
      this.getProducts(this.filterReq);
    },
    async deleteRecord(params) {
      this.$swal({
        title: "Are you sure?",
        text: "Want to delete !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await this.deleteShopProductAction(params);
            console.log("res===", res);
            this.getProducts({ pageNo: 1, limit: 10 });
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              icon: "success",
              title: res?.message,
              didOpen: (toast) => {
                toast.onmouseenter = this.$swal.stopTimer;
                toast.onmouseleave = this.$swal.resumeTimer;
              },
            });
          } catch (error) {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              icon: "error",
              title: error,
              didOpen: (toast) => {
                toast.onmouseenter = this.$swal.stopTimer;
                toast.onmouseleave = this.$swal.resumeTimer;
              },
            });
          }
        }
      });
    },

    async getCategories(params) {
      try {
        this.fetchCategories(params);
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
  },
  mounted() {
    this.getShop();
    const shopId = this.$route.params.id;
    if (shopId) {
      this.getProducts({ shopId, pageNo: 1, limit: 10 });
    }
  },
  created() {
    this.getCategories({ pagination: false });
  },

  computed: {
    ...mapGetters([
      "shopDetails",
      "shopProducts",
      "totalPages",
      "currentPage",
      "totalProduct",
      "inStock",
      "outOfStock",
      "categories",
    ]),
    getShopId() {
      return this.$route.params.id;
    },
  },
};
</script>
<style scoped>
.c-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-group {
  display: flex;
}
</style>
