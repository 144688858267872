<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header pb-0">
          <div class="d-flex align-items-center">
            <p class="mb-0">Add Product</p>
          </div>
        </div>
        <div class="card-body">
          <p class="text-uppercase text-sm">Add product to warehouse</p>
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="selectBrand">Select Shop</label>
                  <select
                    @change="handleShopChange"
                    class="form-control"
                    id="selectBrand"
                    :class="{ 'is-invalid': formError.shopId }"
                  >
                    <option>-Choose category-</option>
                    <option
                      v-for="shop of shops"
                      :key="shop._id"
                      :value="shop._id"
                    >
                      {{ shop.name }} ({{ shop.city }}), {{ shop.address }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ formError?.shopId }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="selectBrand">Select Category</label>
                  <select
                    @change="handleCategoryChange"
                    class="form-control"
                    id="selectBrand"
                    :class="{ 'is-invalid': formError.category }"
                  >
                    <option value="">-Choose category-</option>
                    <option
                      v-for="category of categories?.data"
                      :key="category._id"
                      :value="category._id"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ formError?.category }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="selectBrand">Select Brand</label>
                  <select
                    @change="handleBrandChange"
                    class="form-control"
                    id="selectBrand"
                    :class="{ 'is-invalid': formError.brand }"
                  >
                    <option>-Choose category-</option>
                    <option
                      v-for="brand of allBrands"
                      :key="brand._id"
                      :value="brand._id"
                    >
                      {{ brand.name }} ({{ brand.packing }}), {{ brand.MRP }}rs
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ formError?.brand }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name" class="form-control-label">Name</label>
                  <input
                    id="name"
                    class="form-control"
                    type="text"
                    v-model="product.name"
                    :class="{ 'is-invalid': formError.name }"
                  />
                  <div class="invalid-feedback">{{ formError?.name }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="Quantity" class="form-control-label"
                    >Qty in Box (In Cases)</label
                  >
                  <input
                    id="Quantity"
                    class="form-control"
                    type="number"
                    v-model="product.boxQty"
                    :class="{ 'is-invalid': formError.boxQty }"
                  />
                  <small class="text-sm-left text-info">{{
                    product.qrtPerBoxMsg
                  }}</small>
                  <div class="invalid-feedback">{{ formError?.boxQty }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="Quantity" class="form-control-label"
                    >Qty in Loose (In Btls)</label
                  >
                  <input
                    id="Quantity"
                    class="form-control"
                    type="number"
                    v-model="product.looseQty"
                    :class="{ 'is-invalid': formError.looseQty }"
                  />
                  <div class="invalid-feedback">{{ formError?.looseQty }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="Warranty" class="form-control-label"
                    >Warranty Rate</label
                  >
                  <input
                    id="Warranty"
                    class="form-control"
                    type="number"
                    step="0.0001"
                    v-model="product.warranty"
                    :class="{ 'is-invalid': formError.warranty }"
                  />
                  <div class="invalid-feedback">{{ formError?.warranty }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="MRP" class="form-control-label"
                    >MRP <i class="fa fa-inr" aria-hidden="true"></i
                  ></label>
                  <input
                    id="MRP"
                    class="form-control"
                    type="number"
                    disabled="true"
                    step="0.01"
                    v-model="product.MRP"
                    :class="{ 'is-invalid': formError.MRP }"
                  />
                  <div class="invalid-feedback">{{ formError?.MRP }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="Purchase" class="form-control-label"
                    >Purchase Price/Item</label
                  >
                  <input
                    id="Purchase"
                    class="form-control"
                    type="number"
                    step="0.01"
                    v-model="product.purchasePrice"
                    :class="{ 'is-invalid': formError.purchasePrice }"
                  />
                  <div class="invalid-feedback">
                    {{ formError?.purchasePrice }}
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="Selling" class="form-control-label"
                    >Selling Price/Item</label
                  >
                  <input
                    id="Selling"
                    class="form-control"
                    type="number"
                    step="0.01"
                    v-model="product.sellingPrice"
                    :class="{ 'is-invalid': formError.sellingPrice }"
                  />
                  <div class="invalid-feedback">
                    {{ formError?.sellingPrice }}
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="Packing" class="form-control-label"
                    >Packing</label
                  >
                  <input
                    id="Packing"
                    class="form-control"
                    type="text"
                    disabled="true"
                    v-model="product.packing"
                    :class="{ 'is-invalid': formError.packing }"
                  />
                  <div class="invalid-feedback">{{ formError?.packing }}</div>
                </div>
              </div>
            </div>
            <br />
            <argon-button
              type="submit"
              color="primary"
              size="sm"
              class="ms-auto me-2"
              :disabled="isButtonDisabled"
              >Save</argon-button
            >
            <argon-button
              @click="cancel"
              type="button"
              color="danger"
              size="sm"
              class="ms-auto"
              >Cancel</argon-button
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    const formError = {};
    const isButtonDisabled = false;
    return {
      product: {
        brandId: "",
        name: "",
        shopId: "",
        category: "",
        packing: "",
        MRP: "",
        warranty: "",
        purchasePrice: "",
        sellingPrice: "",
        boxQty: "",
        looseQty: "",
        qrtPerBox: 48,
        qrtPerBoxMsg: "",
      },
      formError,
      isButtonDisabled,
    };
  },
  methods: {
    ...mapActions([
      "fetchAllBrands",
      "fetchCategories",
      "fetchShops",
      "setCreateProduct",
      "createProductAction",
    ]),

    handleShopChange(event) {
      const shopId = event.target.value;
      this.product.shopId = shopId;
    },

    handleCategoryChange(event) {
      const categoryId = event.target.value;
      this.product.category = categoryId;
      if (categoryId) {
        this.getBrands({ categoryId });
      }
    },

    handleBrandChange(event) {
      const brandId = event.target.value;
      const selectedBrand = this.allBrands.find((brand) => {
        if (String(brand._id) === String(brandId)) {
          return brand;
        }
      });

      this.product = {
        ...this.product,
        brandId: selectedBrand?._id,
        name: selectedBrand?.name,
        category: selectedBrand?.category,
        packing: selectedBrand?.packing,
        MRP: selectedBrand?.MRP,
        warranty: selectedBrand?.warranty,
        purchasePrice: "",
        sellingPrice: selectedBrand?.MRP,
        boxQty: 1,
        looseQty: 1,
        qtyPerBox: selectedBrand?.qtyPerBox,
        qrtPerBoxMsg: `Have ${selectedBrand?.qtyPerBox} Qty in box`,
        warehouseId: localStorage.getItem("warehouseId"),
      };

      if (!selectedBrand?.qtyPerBox) {
        this.product.boxQty = 0;
        this.isButtonDisabled = true;
        this.$swal({
          icon: "error",
          title: "Quantity per box not define",
          text: "Please add qty per box respect to this brand first !",
        });
      } else {
        this.isButtonDisabled = false;
      }
    },
    async getBrands(params) {
      try {
        return await this.fetchAllBrands(params);
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    errorHandler() {
      let isSubmit = true;
      for (let field in this.product) {
        if (this.product[field] === "") {
          if (field === "brandId") field = "brand";
          const fieldName = field
            .replace(/([a-z0-9])([A-Z])/g, "$1-$2")
            .toLowerCase();
          this.formError[field] = `${fieldName} is required!`;
          isSubmit = false;
        }
      }
      isSubmit ? (this.formError = {}) : "";
      return isSubmit;
    },
    async submitForm() {
      console.log("productSubmit====", this.product);
      if (await this.errorHandler()) {
        const res = await this.createProductAction(this.product);
        console.log("res====", res);
        this.$router.push({ name: "Warehouse" });
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "success",
          title: res?.data?.message,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    cancel() {
      this.$router.push(`/warehouse`);
    },
  },
  created() {
    this.fetchCategories({ pageNo: 1, limit: 10 });
    this.fetchShops({ pageNo: 1, limit: 10 });
  },
  computed: {
    ...mapGetters(["shops", "categories", "allBrands", "getCreateProductData"]),
  },
};
</script>
