import axiosInstance from "@/axiosInstance";

const state = {
  user: JSON.parse(localStorage.getItem("user")) || {},
  token: localStorage.getItem("token") || "",
  error: null,
};

const actions = {
  async login({ commit }, params) {
    try {
      const response = await axiosInstance.post(`/users/login`, {
        email: params.email,
        password: params.password,
      });
      console.log("fromLoginAction====", response.data);
      const token = response?.data?.token;
      const user = response?.data?.userData;
      const warehouseId = response?.data?.warehouseId;
      await commit("setToken", token);
      localStorage.setItem("token", token);
      localStorage.setItem("warehouseId", warehouseId);
      localStorage.setItem("user", JSON.stringify(user));
      axiosInstance.defaults.headers.common["Authorization"] =
        `Bearer ${token}`;

      await commit("setUser", user);
      return response;
    } catch (error) {
      console.log("error from action=====", error);
      commit("setError", "Failed to fetch data. Please try again later.");
      throw error;
    }
  },
  async logout({ commit }) {
    try {
      console.log("calling-logout-action=====");
      localStorage.removeItem("token");
      localStorage.removeItem("warehouseId");
      localStorage.removeItem("user");
      delete axiosInstance.defaults.headers.common["Authorization"];
      await commit("setToken", "");
      await commit("setUser", {});
      const response = await axiosInstance.post(`/users/logout`);
      console.log("fromLogoutAction====", response.data);
    } catch (error) {
      console.log("error from action=====", error);
      commit("setError", "Failed to fetch data. Please try again later.");
    }
  },
};

const mutations = {
  setUser: (state, user) => {
    state.user = user;
  },
  setError: (state, error) => {
    state.error = error;
  },
  setToken: (state, token) => {
    state.token = token;
  },
};

const getters = {
  getUser: (state) => state.user,
  isLoggedIn: (state) => !!state.token,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
