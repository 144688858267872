<script setup>
import Pagination from "@/components/Pagination.vue";
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h5 class="card-title">Product Filter</h5>
              <div class="d-flex">
                <router-link
                  class="btn btn-sm btn-primary me-2"
                  :to="{ name: 'AddSale' }"
                >
                  <i class="fa fa-plus" aria-hidden="true"></i> Add Sale
                </router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label for="example-text-input" class="form-control-label"
                  >Name</label
                >
                <div class="input-group">
                  <span class="input-group-text text-body">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    v-model="filterReq.search"
                    type="text"
                    class="form-control"
                    placeholder="Search product name..."
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="exampleFormControlSelect1">Select Shop</label>
                  <select
                    v-model="filterReq.shop"
                    class="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <option value="">-All Shops-</option>
                    <option
                      v-for="shop of shops"
                      :key="shop._id"
                      :value="shop._id"
                    >
                      {{ shop.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="exampleFormControlSelect1">Select Category</label>
                  <select
                    v-model="filterReq.category"
                    class="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <option value="">-Choose category-</option>
                    <option
                      v-for="category of categories?.data"
                      :key="category._id"
                      :value="category._id"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label for="example-text-input" class="form-control-label"
                  >From Date</label
                >
                <div class="input-group">
                  <span class="input-group-text text-body">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    v-model="filterReq.fromDate"
                    type="date"
                    class="form-control"
                    placeholder="Search product name..."
                  />
                </div>
              </div>
              <div class="col-md-4">
                <label for="example-text-input" class="form-control-label"
                  >To Date</label
                >
                <div class="input-group">
                  <span class="input-group-text text-body">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    v-model="filterReq.toDate"
                    type="date"
                    class="form-control"
                    placeholder="Search product name..."
                  />
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-5">
                <button
                  @click="filter"
                  class="btn btn-info btn-sm ms-auto me-2"
                >
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
                <button
                  @click="resetFilter"
                  class="btn btn-warning btn-sm ms-auto"
                >
                  <i class="fas fa-undo"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <SaleTableComponent
      heading="Sales Table"
      :headers="tableHead"
      :data="sales"
      button="Add Product"
      @delete-record="deleteRecord"
    />
  </div>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="d-flex justify-content-center">
        <Pagination
          :totalPages="totalPages"
          :currentPage="currentPage"
          @page-changed="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SaleTableComponent from "./components/SaleTableComponent.vue";
import ProductFilterCard from "./components/ProductFilterCard.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    SaleTableComponent,
    ProductFilterCard,
  },
  data() {
    const filterReq = {
      pageNo: 1,
      limit: 10,
      shop: "",
      search: "",
      category: "",
      fromDate: "",
      toDate: "",
    };
    return {
      filterReq,
      tableHead: [
        { id: 1, name: "Name" },
        { id: 2, name: "Category", padding: "ps-2" },
        { id: 3, name: "Shop" },
        { id: 4, name: "Packing" },
        { id: 5, name: "Qty", align: "center" },
        { id: 6, name: "Selling Price", align: "center" },
        { id: 6, name: "Order Amount", align: "center" },
        { id: 7, name: "Date", align: "center" },
        { id: 8, name: "Action", align: "center" },
      ],
    };
  },
  methods: {
    ...mapActions([
      "fetchSalesAction",
      "fetchShops",
      "fetchCategories",
      "deleteSellAction",
    ]),
    async getShops(params) {
      try {
        await this.fetchShops(params);
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    async getSales(params) {
      try {
        await this.fetchSalesAction(params);
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    handlePageChange(page) {
      this.filterReq.pageNo = page;
      this.getSales(this.filterReq);
    },
    filter() {
      this.filterReq.pageNo = 1;
      this.getSales(this.filterReq);
    },
    resetFilter() {
      this.filterReq.search = "";
      this.filterReq.category = "";
      this.filterReq.shop = "";
      this.filterReq.pageNo = 1;
      this.filterReq.limit = 10;
      this.filterReq.fromDate = "";
      this.filterReq.toDate = "";
      this.getSales(this.filterReq);
    },
    async getCategories(params) {
      try {
        this.fetchCategories(params);
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    async deleteRecord(id) {
      console.log("deleteRecord===", id);

      this.$swal({
        title: "Are you sure?",
        text: "Want to delete !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await this.deleteSellAction({ id });
            // console.log("res===", res);
            await this.getSales(this.filterReq);
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              icon: "success",
              title: res?.message,
              didOpen: (toast) => {
                toast.onmouseenter = this.$swal.stopTimer;
                toast.onmouseleave = this.$swal.resumeTimer;
              },
            });
          } catch (error) {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              icon: "error",
              title: error,
              didOpen: (toast) => {
                toast.onmouseenter = this.$swal.stopTimer;
                toast.onmouseleave = this.$swal.resumeTimer;
              },
            });
          }
        }
      });
    },
  },

  created() {
    this.getSales({ pageNo: 1, limit: 10 });
    this.getShops({ pageNo: 1, limit: 100 });
    this.getCategories({ pagination: false });
  },

  computed: {
    ...mapGetters([
      "sales",
      "totalPages",
      "currentPage",
      "totalProduct",
      "shops",
      "categories",
    ]),
  },
};
</script>
<style scoped>
.c-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-group {
  display: flex;
}
</style>
