<script setup>
import CategoryTable from "./components/CategoryTable.vue";
// import Pagination from "@/components/Pagination.vue";
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <CategoryTable
          heading="Categories Table"
          :headers="tableHead"
          :categories="categories?.data"
        />
      </div>
    </div>
    <!-- <Pagination /> -->

    <!-- <div class="mt-4 row">
      <div class="col-12">
        <projects-table />
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    CategoryTable,
  },
  methods: {
    ...mapActions(["fetchCategories"]),
    async getCategories() {
      try {
        await this.fetchCategories({ pageNo: 1, limit: 10 });
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
  },
  data() {
    return {
      tableHead: [
        { id: 1, name: "Name" },
        { id: 2, name: "Type", padding: "ps-2" },
        { id: 3, name: "Status", align: "center" },
      ],
    };
  },
  created() {
    this.getCategories();
  },
  computed: {
    ...mapGetters(["categories"]),
  },
};
</script>
