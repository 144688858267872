<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>{{ heading }}</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-xxs font-weight-bolder ps-2"
                :class="[
                  header.align ? 'text-center' : 'text-left',
                  header.padding || '',
                  { sortable: header.sorting },
                ]"
                v-for="header in headers"
                :key="header.id"
                @click="header.sorting ? productSort() : null"
              >
                {{ header.name }}
                <span class="font-weight-bold" v-if="header.sorting">
                  <i :class="sortIcon"></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr :key="item.id" v-for="(item, index) in data">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <img
                      :src="item.image"
                      class="avatar avatar-sm me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-secondary text-xs">
                      {{ item.name }}
                    </h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs mb-0">
                  {{ item.category.name }}
                </p>
              </td>
              <td>
                <p class="text-xs mb-0">{{ item.packing }}</p>
              </td>
              <td>
                <p
                  :class="[
                    'text-xs mb-0',
                    { 'text-danger font-weight-bold': item.qty < 10 },
                  ]"
                >
                  <span id="qty">{{ item.qty }}</span>
                </p>
              </td>
              <td>
                <p class="text-xs mb-0">
                  {{ item.sellingPrice }}
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="badge badge-sm bg-gradient-success">{{
                  item.status
                }}</span>
              </td>

              <!-- <td class="align-middle text-center text-sm">
                <button
                  class="btn btn-outline-danger"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Delete Product"
                  @click="
                    deleteRow({ shopId: item?.shopId, productId: item._id })
                  "
                >
                  <i class="fas fa-trash fa-lg" style="color: #f5365c"></i>
                </button>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    heading: String,
    headers: Array,
    data: Array,
  },
  data() {
    return {
      sort: 0,
    };
  },
  computed: {
    sortIcon() {
      if (this.sort === -1) {
        return "bi bi-sort-up icon-size";
      } else if (this.sort === 1) {
        return "bi bi-sort-down icon-size";
      } else {
        return "bi bi-arrow-down-up icon-size";
      }
    },
  },
  methods: {
    ...mapActions(["updateShopProductQtyAction"]),
    deleteRow(params) {
      this.$emit("delete-record", params);
    },
    productSort() {
      if (this.sort === 0) {
        this.sort = 1;
      } else if (this.sort === 1) {
        this.sort = -1;
      } else {
        this.sort = 0;
      }
      this.$emit("sort", this.sort);
    },
  },
};
</script>
<style scoped>
@import "https://cdn.jsdelivr.net/npm/bootstrap-icons/font/bootstrap-icons.css";
.sortable {
  cursor: pointer;
}
.icon-size {
  font-size: 1.25em;
}
</style>
