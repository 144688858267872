import axiosInstance from "@/axiosInstance";

const state = {
  shops: [],
  shopDetails: {},
  totalProduct: "",
  inStock: "",
  outOfStock: "",
};

const actions = {
  async fetchShops({ commit }, params) {
    try {
      const { pageNo = 1, limit = 10 } = params;
      const response = await axiosInstance.get(
        `/shops?pageNo=${pageNo}&limit=${limit}`
      );
      await commit("setShops", response?.data?.results?.data);
      await commit("setTotalPages", response?.data?.results?.totalPage);
      await commit("setCurrentPage", Number(response?.data?.results?.pageNo));
      return;
    } catch (error) {
      console.log("error from shops action=====", error);
      return Promise.reject("Failed to fetch data. Please try again later");
    }
  },
  async fetchShopDetails({ commit }, params) {
    try {
      const { id } = params;
      const response = await axiosInstance.get(`/shops/${id}`);
      await commit("setShopDetails", response?.data?.results?.shop);
      await commit("setTotalProduct", response?.data?.results?.totalProduct);
      await commit("setInStock", response?.data?.results?.inStock);
      await commit("setOutOfStock", response?.data?.results?.outOfStock);
      return;
    } catch (error) {
      console.log("error from shops action=====", error);
      return Promise.reject("Failed to fetch data. Please try again later");
    }
  },
  async updateShopDetails({ commit }, params) {
    try {
      const { id } = params;
      const response = await axiosInstance.put(`/shops/${id}`, params);
      console.log(response?.data?.message);
      return response;
    } catch (error) {
      console.log("error from shops action=====", error);
      return Promise.reject("Failed to update data. Please try again later");
    }
  },
};

const mutations = {
  setShops: (state, data) => {
    state.shops = data;
  },
  setShopDetails: (state, data) => {
    state.shopDetails = data;
  },
  setTotalProduct: (state, data) => {
    state.totalProduct = data;
  },
  setInStock: (state, data) => {
    state.inStock = data;
  },
  setOutOfStock: (state, data) => {
    state.outOfStock = data;
  },
};

const getters = {
  shops: (state) => state.shops,
  shopDetails: (state) => state.shopDetails,
  totalProduct: (state) => state.totalProduct,
  inStock: (state) => state.inStock,
  outOfStock: (state) => state.outOfStock,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
