import axiosInstance from "@/axiosInstance";

const state = {
  warehouse: {},
};

const actions = {
  async fetchWarehouse({ commit }) {
    try {
      const response = await axiosInstance.get(`/warehouses`);
      await commit("setWarehouse", response?.data?.results);
      return;
    } catch (error) {
      console.log("error from warehouse action=====", error);
      return Promise.reject(error);
    }
  },
  async updateWarehouse({ commit }, params) {
    try {
      console.log("params=====", params);
      const response = await axiosInstance.put(
        `/warehouses/${params?.id}`,
        params
      );
      return response;
    } catch (error) {
      console.log("error from warehouse action=====", error);
      return Promise.reject(error);
    }
  },
};

const mutations = {
  setWarehouse: (state, data) => {
    state.warehouse = data;
  },
};

const getters = {
  warehouse: (state) => state.warehouse,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
