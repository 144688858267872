<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-profile">
          <img
            src="../../assets/img/warehouse2.jpg"
            alt="Image placeholder"
            class="card-img-top"
          />
          <br />

          <div class="card-body pt-0">
            <div class="row">
              <div class="col">
                <div class="d-flex justify-content-center">
                  <div class="d-grid text-center">
                    <span class="text-lg text-primary font-weight-bolder">{{
                      totalProduct
                    }}</span>
                    <span class="text-sm text-primary opacity-8"
                      >Total Product</span
                    >
                  </div>
                  <div class="d-grid text-center mx-4">
                    <span class="text-lg text-success font-weight-bolder">{{
                      inStockProduct
                    }}</span>
                    <span class="text-sm text-success opacity-8">In stock</span>
                  </div>
                  <div class="d-grid text-center">
                    <span class="text-lg text-danger font-weight-bolder">{{
                      outOfStockProduct
                    }}</span>
                    <span class="text-sm text-danger opacity-8"
                      >Out of stock</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-4">
              <h5>
                {{ warehouse.name }}
              </h5>
              <div class="h6 font-weight-300">
                <i class="ni location_pin mr-2"></i>{{ warehouse.city }}
              </div>
              <div class="h6 mt-4">
                <i class="ni business_briefcase-24 mr-2"></i
                >{{ warehouse.address }}
              </div>
              <div>
                {{ warehouse.state }}
                <span class="font-weight-light"
                  >, {{ warehouse?.pincode }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="mb-0">Edit Warehouse</p>
            </div>
          </div>
          <div class="card-body">
            <p class="text-uppercase text-sm">Warehouse Information</p>
            <form @submit.prevent="updateWarehouseReq">
              <div class="row">
                <div class="col-md-12">
                  <label for="example-text-input" class="form-control-label"
                    >Name</label
                  >
                  <input
                    id="name"
                    class="form-control"
                    type="text"
                    v-model="warehouse.name"
                    :class="{ 'is-invalid': formError?.name }"
                  />
                  <div class="invalid-feedback">{{ formError?.name }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label for="example-text-input" class="form-control-label"
                    >Address</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="warehouse.address"
                    :class="{ 'is-invalid': formError?.address }"
                  />
                  <div class="invalid-feedback">{{ formError?.address }}</div>
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >City</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="warehouse.city"
                    :class="{ 'is-invalid': formError?.city }"
                  />
                  <div class="invalid-feedback">{{ formError?.city }}</div>
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >State</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="warehouse.state"
                    :class="{ 'is-invalid': formError?.state }"
                  />
                  <div class="invalid-feedback">{{ formError?.state }}</div>
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >Pin code</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="warehouse.pincode"
                    :class="{ 'is-invalid': formError?.pincode }"
                  />
                  <div class="invalid-feedback">{{ formError?.pincode }}</div>
                </div>
              </div>
              <br />
              <argon-button
                type="submit"
                color="primary"
                size="sm"
                class="ms-auto"
                >Save</argon-button
              >
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: {
    warehouse: {
      type: Object,
      default: {},
    },
    totalProduct: Number,
    inStockProduct: Number,
    outOfStockProduct: Number,
  },
  data() {
    const formError = {};
    return {
      formError,
    };
  },
  methods: {
    ...mapActions(["updateWarehouse"]),
    async updateWarehouseReq() {
      if (this.errorHandler(this.warehouse)) {
        try {
          const res = await this.updateWarehouse(this.warehouse);
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
            title: res?.data?.message,
            didOpen: (toast) => {
              toast.onmouseenter = this.$swal.stopTimer;
              toast.onmouseleave = this.$swal.resumeTimer;
            },
          });
        } catch (error) {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "error",
            title: error,
            didOpen: (toast) => {
              toast.onmouseenter = this.$swal.stopTimer;
              toast.onmouseleave = this.$swal.resumeTimer;
            },
          });
        }
      }
    },

    errorHandler(fields) {
      this.formError = {};
      let isSubmit = true;
      for (let key in fields) {
        if (fields[key] === "") {
          const fieldName = key
            .replace(/([a-z0-9])([A-Z])/g, "$1-$2")
            .toLowerCase();
          this.formError[key] = `${fieldName} is required`;
          isSubmit = false;
        }
      }
      return isSubmit;
    },
  },
};
</script>
