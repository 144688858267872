<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header pb-0">
          <div class="d-flex align-items-center">
            <p class="mb-0">Add Sell</p>
          </div>
        </div>
        <div class="card-body">
          <p class="text-uppercase text-sm">Add Daily Sell</p>
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="selectBrand">Select Shop</label>
                  <select
                    @change="handleShopChange"
                    class="form-control"
                    id="selectBrand"
                    :class="{ 'is-invalid': formError.shopId }"
                  >
                    <option>-Choose category-</option>
                    <option
                      v-for="shop of shops"
                      :key="shop._id"
                      :value="shop._id"
                    >
                      {{ shop.name }} ({{ shop.city }}), {{ shop.address }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ formError?.shopId }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="selectProduct">Select Product From Shop</label>
                  <select
                    @change="handleProductChange"
                    class="form-control"
                    ref="productSelect"
                    id="selectProduct"
                    :class="{ 'is-invalid': formError.product }"
                  >
                    <option value="">-Choose Product-</option>
                    <option
                      v-for="product of products"
                      :key="product._id"
                      :value="product._id"
                    >
                      {{ product.name }} ({{ product.packing }}),
                      {{ product.MRP }}rs
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ formError?.product }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="Quantity" class="form-control-label"
                    >Qty in Box (In Cases)</label
                  >
                  <input
                    id="Quantity"
                    class="form-control"
                    type="number"
                    v-model="product.boxQty"
                    :class="{ 'is-invalid': formError.boxQty }"
                  />

                  <div class="invalid-feedback">{{ formError?.boxQty }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="QuantityInLoose" class="form-control-label"
                    >Qty in Loose (In Btls)</label
                  >
                  <input
                    id="QuantityInLoose"
                    class="form-control"
                    type="number"
                    v-model="product.looseQty"
                    :class="{ 'is-invalid': formError.looseQty }"
                  />
                  <div class="invalid-feedback">{{ formError?.looseQty }}</div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="Quantity" class="form-control-label">MRP</label>
                  <input
                    id="Quantity"
                    class="form-control"
                    type="number"
                    v-model="product.MRP"
                    disabled
                    :class="{ 'is-invalid': formError.MRP }"
                  />
                  <div class="invalid-feedback">{{ formError?.MRP }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="QuantityInLoose" class="form-control-label"
                    >Discount (In rupee)</label
                  >
                  <input
                    id="QuantityInLoose"
                    class="form-control"
                    type="number"
                    @input="handleDiscountChange"
                    v-model="product.discount"
                    :class="{ 'is-invalid': formError.discount }"
                  />
                  <div class="invalid-feedback">
                    {{ formError?.discount }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="QuantityInLoose" class="form-control-label"
                    >Actual Selling Price</label
                  >
                  <input
                    id="QuantityInLoose"
                    class="form-control"
                    type="number"
                    v-model="product.sellingPrice"
                    disabled="true"
                    :class="{ 'is-invalid': formError.sellingPrice }"
                  />
                  <div class="invalid-feedback">
                    {{ formError?.sellingPrice }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="Quantity" class="form-control-label">Date</label>
                  <input
                    id="Quantity"
                    class="form-control"
                    type="date"
                    v-model="product.date"
                    :class="{ 'is-invalid': formError.date }"
                  />
                  <div class="invalid-feedback">{{ formError?.date }}</div>
                </div>
              </div>
            </div>
            <br />
            <argon-button
              type="submit"
              color="primary"
              size="sm"
              class="ms-auto me-2"
              >Save</argon-button
            >
            <argon-button
              @click="cancel"
              type="button"
              color="danger"
              size="sm"
              class="ms-auto"
              >Cancel</argon-button
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    const formError = {};
    const availableQtnInBox = 0;
    const availableQtnInLoose = 0;
    const formattedDate = this.formatDate(new Date());
    return {
      product: {
        shopId: "",
        productId: "",
        MRP: "",
        discount: 0,
        sellingPrice: "",
        date: formattedDate,
        boxQty: "",
        looseQty: "",
        availableQtnInBoxMsg: "",
        availableQtnInLooseMsg: "",
      },
      formError,
      availableQtnInBox,
      availableQtnInLoose,
    };
  },
  methods: {
    ...mapActions(["fetchShops", "fetchShopProducts", "addSellAction"]),
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    handleShopChange(event) {
      const shopId = event.target.value;
      this.resetForm();
      this.product.shopId = shopId;
      this.getProducts({ shopId, pageNo: 1, limit: 100 });
    },

    handleProductChange(event) {
      const productId = event.target.value;
      if (productId) {
        const selectedProduct = this.products.find((product) => {
          if (String(product._id) === String(productId)) {
            return product;
          }
        });
        const availableBox = Math.floor(
          selectedProduct.qty / selectedProduct?.qtyPerBox
        );

        const availableLoose = Math.floor(
          selectedProduct.qty % selectedProduct?.qtyPerBox
        );

        this.availableQtnInBox = availableBox;
        this.availableQtnInLoose = availableLoose;

        this.product = {
          ...this.product,
          productId: selectedProduct?._id,
          boxQty: "",
          looseQty: "",
          qtyPerBox: selectedProduct?.qtyPerBox,
          MRP: selectedProduct?.MRP,
          discount: 0,
          sellingPrice: selectedProduct?.MRP,
          availableQtnInBoxMsg: `Available ${availableBox || 0} Qty in box`,
          availableQtnInLooseMsg: `Available ${availableLoose || 0} Qty in Loose`,
          warehouseId: localStorage.getItem("warehouseId"),
        };
      }
    },
    async getProducts(params) {
      try {
        return await this.fetchShopProducts(params);
      } catch (error) {
        this.catchError(error);
      }
    },

    handleDiscountChange(e) {
      const discount = e.target.value || 0;
      const mrp = this.product.MRP;
      if (mrp) {
        this.product.sellingPrice = mrp - discount;
      }
    },
    errorHandler() {
      this.formError = {};
      let isSubmit = true;
      for (let field in this.product) {
        if (this.product[field] === "") {
          if (field === "productId") field = "product";
          const fieldName = field
            .replace(/([a-z0-9])([A-Z])/g, "$1-$2")
            .toLowerCase();
          this.formError[field] = `${fieldName} is required`;
          isSubmit = false;
        }
      }
      isSubmit ? (this.formError = {}) : "";
      return isSubmit;
    },
    async submitForm() {
      if (await this.errorHandler()) {
        try {
          const res = await this.addSellAction({
            shopId: this.product.shopId,
            shopName: this.product.shopName,
            productId: this.product.productId,
            boxQty: this.product.boxQty,
            looseQty: this.product.looseQty,
            date: new Date(this.product.date).getTime(),
            sellingPrice: this.product.sellingPrice,
            discount: this.product.discount,
          });
          this.resetForm();
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
            title: res?.data?.message,
            didOpen: (toast) => {
              toast.onmouseenter = this.$swal.stopTimer;
              toast.onmouseleave = this.$swal.resumeTimer;
            },
          });
        } catch (error) {
          this.catchError(error);
        }
      }
    },

    resetForm() {
      this.product = {
        ...this.product,
        productId: "",
        MRP: "",
        sellingPrice: "",
        date: this.formatDate(new Date()),
        boxQty: "",
        looseQty: "",
        availableQtnInBoxMsg: "",
        availableQtnInLooseMsg: "",
      };
      this.$refs.productSelect.value = "";
    },
    catchError(error) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        icon: "error",
        title: error,
        didOpen: (toast) => {
          toast.onmouseenter = this.$swal.stopTimer;
          toast.onmouseleave = this.$swal.resumeTimer;
        },
      });
    },
    // Redirect to shop-details
    cancel() {
      this.$router.push(`/sales`);
    },
  },

  created() {
    this.fetchShops({ pageNo: 1, limit: 10 });
  },
  computed: {
    ...mapGetters(["shops", "products"]),
  },
};
</script>
