import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const appInstance = createApp(App);
appInstance.use(VueSweetalert2);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
// Set up global error handler
appInstance.config.errorHandler = (err, vm, info) => {
  console.error("Error:", err);
  console.error("Info:", info);

  // Show error alert using SweetAlert2
  appInstance.config.globalProperties.$swal({
    title: "Error",
    text: err.message,
    icon: "error",
  });

  // Optionally, you can log the error to an external service
  // logErrorToService(err, vm, info);
};

appInstance.mount("#app");
