<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
import Pagination from "@/components/Pagination.vue";
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <WarehouseCard
          :warehouse="warehouse.warehouse"
          :total-product="warehouse.totalProduct"
          :in-stock-product="warehouse.inStockProduct"
          :out-of-stock-product="warehouse.outOfStockProduct"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Product Filter</h5>
            <router-link class="d-flex align-items-center" to="/add-product">
              <argon-button color="success" size="sm" class="ms-auto"
                ><i class="fa fa-plus" aria-hidden="true"></i> Add Product
              </argon-button>
            </router-link>

            <div class="row">
              <div class="col-md-5">
                <label for="example-text-input" class="form-control-label"
                  >Name</label
                >
                <div class="input-group">
                  <span class="input-group-text text-body">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    v-model="filterReq.search"
                    placeholder="Search product name..."
                  />
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label for="exampleFormControlSelect1">Select Category</label>
                  <select
                    v-model="filterReq.category"
                    class="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <option value="">-Choose category-</option>
                    <option
                      v-for="category of categories?.data"
                      :key="category._id"
                      :value="category._id"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <button @click="filter" class="btn btn-info btn-sm ms-auto me-2">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <button
                @click="resetFilter"
                class="btn btn-warning btn-sm ms-auto"
              >
                <i class="fas fa-undo"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <TableComponent
      heading="Warehouse Products"
      :headers="tableHead"
      :data="products"
      @delete-record="deleteRecord"
      @filter="filter"
      @sort="sortProduct"
      button="Add Product"
    />
  </div>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="d-flex justify-content-center">
        <Pagination
          :totalPages="totalPages"
          :currentPage="currentPage"
          @page-changed="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WarehouseCard from "./components/WarehouseCard.vue";
import TableComponent from "./components/TableComponent.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    WarehouseCard,
    TableComponent,
  },
  data() {
    const filterReq = {
      pageNo: 1,
      limit: 10,
      shopId: this.$route.params.id,
      search: "",
      category: "",
      qtySort: 0,
    };
    return {
      tableHead: [
        { id: 1, name: "Name" },
        { id: 2, name: "Category", padding: "ps-2" },
        { id: 3, name: "Packing" },
        { id: 4, name: "Qty", sorting: true },
        { id: 5, name: "Selling Price" },
        { id: 6, name: "Status", align: "center" },
        { id: 7, name: "Action", align: "center" },
      ],
      filterReq,
    };
  },
  methods: {
    ...mapActions([
      "fetchWarehouse",
      "fetchProducts",
      "fetchCategories",
      "deleteProductAction",
    ]),

    async getWarehouse() {
      try {
        await this.fetchWarehouse();
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },

    async getProducts(params) {
      try {
        await this.fetchProducts(params);
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    handlePageChange(page) {
      this.getProducts({ pageNo: page, limit: 10 });
    },
    filter() {
      this.filterReq.pageNo = 1;
      this.getProducts(this.filterReq);
    },
    sortProduct(sortOrder) {
      this.filterReq.pageNo = 1;
      this.filterReq.qtySort = sortOrder;
      this.getProducts(this.filterReq);
    },
    resetFilter() {
      this.filterReq.search = "";
      this.filterReq.category = "";
      this.filterReq.pageNo = 1;
      this.filterReq.limit = 10;
      this.getProducts({ search: this.search, category: this.categoryFilter });
    },
    async deleteRecord(id) {
      console.log("deleteRecord===", id);

      this.$swal({
        title: "Are you sure?",
        text: "Want to delete !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await this.deleteProductAction({ id });
            console.log("res===", res);
            this.getProducts({ pageNo: 1, limit: 10 });
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              icon: "success",
              title: res?.message,
              didOpen: (toast) => {
                toast.onmouseenter = this.$swal.stopTimer;
                toast.onmouseleave = this.$swal.resumeTimer;
              },
            });
          } catch (error) {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              icon: "error",
              title: error,
              didOpen: (toast) => {
                toast.onmouseenter = this.$swal.stopTimer;
                toast.onmouseleave = this.$swal.resumeTimer;
              },
            });
          }
        }
      });
    },
  },

  created() {
    this.getWarehouse();
    this.getProducts({ pageNo: 1, limit: 10 });
    this.fetchCategories({ pagination: false });
  },
  computed: {
    ...mapGetters([
      "warehouse",
      "products",
      "totalPages",
      "currentPage",
      "categories",
    ]),
  },
};
</script>
