<script setup>
import ShopTable from "./components/ShopTable.vue";
import Pagination from "@/components/Pagination.vue";
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <ShopTable
          heading="Shop Table"
          :headers="tableHead"
          :data="shopsData"
        />
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="d-flex justify-content-center">
          <Pagination
            :totalPages="totalPages"
            :currentPage="currentPage"
            @page-changed="handlePageChange"
          />
        </div>
      </div>
    </div>
    <!-- <div class="mt-4 row">
      <div class="col-12">
        <projects-table />
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ShopTable,
  },
  data() {
    return {
      tableHead: [
        { id: 1, name: "Name" },
        { id: 2, name: "City", padding: "ps-2" },
        { id: 2, name: "State", padding: "ps-2" },
        { id: 2, name: "Address" },
        { id: 3, name: "Status", align: "center" },
        { id: 4, name: "Action", align: "center" },
      ],
    };
  },
  methods: {
    ...mapActions(["fetchShops"]),

    async getShops(params) {
      try {
        await this.fetchShops(params);
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },
    handlePageChange(page) {
      this.getShops({ pageNo: page, limit: 10 });
    },
  },
  created() {
    this.fetchShops({});
  },
  computed: {
    ...mapGetters(["shops", "totalPages", "currentPage"]),
    shopsData() {
      return this.shops;
    },
  },
};
</script>
