import { createStore } from "vuex";
import auth from "./modules/auth";
import category from "./modules/category";
import brand from "./modules/brand";
import warehouse from "./modules/warehouse";
import product from "./modules/product";
import shop from "./modules/shop";
import shopProduct from "./modules/shopProduct";
import sale from "./modules/sale";
import dashboard from "./modules/dashboard";
import balanceSheet from "./modules/balanceSheet";
import history from "./modules/history";

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: false,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    mcolor: "",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    globalError: "",
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    sidebarMinimize(state) {
      let sidenav_show = document.querySelector("#app");
      if (state.isPinned) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        state.isPinned = true;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    setGlobalError(state, message) {
      state.globalError = message;
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
  },
  getters: {},
  modules: {
    auth,
    category,
    brand,
    warehouse,
    product,
    shop,
    shopProduct,
    sale,
    dashboard,
    balanceSheet,
    history,
  },
});
