import axiosInstance from "@/axiosInstance";

const state = {
  products: [],
  totalPages: 0,
  currentPage: 1,
  createProduct: {
    qty: 0,
  },
};

const actions = {
  async fetchProducts({ commit }, params) {
    try {
      const {
        pageNo = 1,
        limit = 10,
        search = "",
        category = "",
        qtySort = "",
      } = params;
      const response = await axiosInstance.get(
        `/products?pageNo=${pageNo}&limit=${limit}&category=${category}&search=${search}&qtySort=${qtySort}`
      );
      await commit("setProducts", response?.data?.results?.data);
      await commit("setTotalPages", response?.data?.results?.totalPage);
      await commit("setCurrentPage", Number(response?.data?.results?.pageNo));
      return;
    } catch (error) {
      console.log("error from products action=====", error);
      return Promise.reject(error);
    }
  },

  async setCreateProduct({ commit }, params) {
    try {
      await commit("setProductCreateDate", params);
      return;
    } catch (error) {
      console.log("error from setCreateProduct action=====", error);
      return Promise.reject(error);
    }
  },

  async createProductAction({ commit }, params) {
    try {
      return await axiosInstance.post(`/products`, params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteProductAction({ commit }, params) {
    try {
      const res = await axiosInstance.delete(`/products/${params.id}`);
      return res?.data;
    } catch (error) {
      console.log("error from products action=====", error);
      return Promise.reject(error);
    }
  },

  async updateQtyAction({ commit }, params) {
    try {
      console.log("updateQtyActio======", params);
      const res = await axiosInstance.patch(`/products/${params.id}`, params);
      return res?.data;
    } catch (error) {
      console.log("error from products action=====", error);
      return Promise.reject(error);
    }
  },
};

const mutations = {
  setProducts: (state, data) => {
    state.products = data;
  },
  setProductCreateDate: (state, data) => {
    state.createProduct = data;
  },
  setTotalPages: (state, totalPages) => {
    state.totalPages = totalPages;
  },
  setCurrentPage: (state, currentPage) => {
    state.currentPage = currentPage;
  },
};

const getters = {
  products: (state) => state.products,
  getCreateProductData: (state) => state.createProduct,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
