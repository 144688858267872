export const Tag = Object.freeze({
  cash: "Cash",
  purchase: "Purchase",
  sale: "Sale",
  salary: "Salary",
  fuel: "Fuel",
  officeExpense: "Office Expense",
  rent: "Rent",
  other: "Other",
});
