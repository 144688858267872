<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};
</script>
<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/dashboard"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
          :navText="isRTL ? 'لوحة القيادة' : 'Dashboard'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <!-- <li class="nav-item">
        <sidenav-item
          to="/user-management"
          :class="getRoute() === 'user-management' ? 'active' : ''"
          :navText="isRTL ? 'لوحة القيادة' : 'User Management'"
        >
          <template v-slot:icon>
            <i class="ni ni ni-circle-08 text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->

      <li class="nav-item">
        <sidenav-item
          to="/categories"
          :class="getRoute() === 'categories' ? 'active' : ''"
          :navText="isRTL ? 'Categories Management' : 'Categories Management'"
        >
          <template v-slot:icon>
            <i class="ni ni-bullet-list-67 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/brands"
          :class="getRoute() === 'brand-management' ? 'active' : ''"
          :navText="isRTL ? 'لوحة القيادة' : 'Brand Management'"
        >
          <template v-slot:icon>
            <i class="ni ni-briefcase-24 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/warehouse"
          :class="getRoute() === 'warehouse' ? 'active' : ''"
          navText="Warehouse Management"
        >
          <template v-slot:icon>
            <i class="ni ni-app text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/shops"
          :class="getRoute() === 'shops' ? 'active' : ''"
          navText="Shops Management"
        >
          <template v-slot:icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/sales"
          :class="getRoute() === 'sales' ? 'active' : ''"
          navText="Sales Management"
        >
          <template v-slot:icon>
            <i class="ni ni-money-coins text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/balance-sheet"
          :class="getRoute() === 'balance-sheet' ? 'active' : ''"
          :navText="
            isRTL ? 'Account & Balance Sheet' : 'Account & Balance Sheet'
          "
        >
          <template v-slot:icon>
            <i class="ni ni-collection text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/history"
          :class="getRoute() === 'history' ? 'active' : ''"
          :navText="isRTL ? 'History' : 'History'"
        >
          <template v-slot:icon>
            <i class="ni ni-compass-04 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <!-- <li class="nav-item">
        <sidenav-item
          to="/employee-management"
          :class="getRoute() === 'employee-management' ? 'active' : ''"
          :navText="isRTL ? 'لوحة القيادة' : 'Employee Management'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->

      <!-- <li class="nav-item">
        <sidenav-item
          to="/document-management"
          :class="getRoute() === 'document-management' ? 'active' : ''"
          :navText="isRTL ? 'لوحة القيادة' : 'Document Management'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->

      <!-- <li class="nav-item">
        <sidenav-item
          to="/guarantee-management"
          :class="getRoute() === 'guarantee-management' ? 'active' : ''"
          :navText="isRTL ? 'لوحة القيادة' : 'Guarantee Management'"
        >
          <template v-slot:icon>
            <i class="ni ni-circle-08 text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->

      <!-- nav bar ------ -->

      <li class="mt-3 nav-item">
        <h6
          v-if="isRTL"
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          صفحات المرافق
        </h6>

        <h6
          v-else
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          ACCOUNT PAGES
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/profile"
          :class="getRoute() === 'profile' ? 'active' : ''"
          :navText="isRTL ? 'حساب تعريفي' : 'Profile'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
    </ul>
  </div>
</template>
