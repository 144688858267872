export function formatDate(date, format) {
  date = new Date(date) || new Date();

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  console.log("month=====", month);
  switch (format) {
    case "yyyy-MM-dd":
      return `${year}-${monthNames[date.getMonth()]}-${day}`;
    case "dd/MM/yyyy":
      return `${day}/${monthNames[date.getMonth()]}/${year}`;
    case "mm-dd-yyyy":
      return `${month}-${day}-${year}`;
    case "yyyy-mm-dd HH:mm:ss":
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    default:
      return date.toString(); // Default to the full date string
  }
}
