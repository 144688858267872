import axiosInstance from "@/axiosInstance";

const state = {
  shopProducts: [],
  totalPages: 0,
  currentPage: 1,
  createProduct: {
    qty: 0,
  },
};

const actions = {
  async fetchShopProducts({ commit }, params) {
    try {
      const {
        shopId,
        pageNo = 1,
        limit = 10,
        search = "",
        category = "",
        qtySort = 0,
      } = params;
      const response = await axiosInstance.get(
        `/shops/${shopId}/products?pageNo=${pageNo}&limit=${limit}&search=${search}&category=${category}&qtySort=${qtySort}`
      );
      await commit("setProducts", response?.data?.results?.data);
      await commit("setTotalPages", response?.data?.results?.totalPage);
      await commit("setCurrentPage", Number(response?.data?.results?.pageNo));
      return;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async importProductAction({ commit }, params) {
    try {
      return await axiosInstance.post(
        `shops/${params.shopId}/products`,
        params
      );
    } catch (error) {
      console.log("error from products action=====", error);
      return Promise.reject(error);
    }
  },
  async deleteShopProductAction({ commit }, params) {
    try {
      const res = await axiosInstance.delete(
        `shops/${params.shopId}/products/${params.productId}`
      );
      return res?.data;
    } catch (error) {
      console.log("error from shop-products action=====", error);
      return Promise.reject(error);
    }
  },
  async updateShopProductQtyAction({ commit }, params) {
    try {
      return await axiosInstance.patch(
        `shops/${params.shopId}/products/${params.productId}`,
        params
      );
    } catch (error) {
      console.log("error from products action=====", error);
      return Promise.reject(error);
    }
  },
};

const mutations = {
  setProducts: (state, data) => {
    state.shopProducts = data;
  },
  setTotalPages: (state, totalPages) => {
    state.totalPages = totalPages;
  },
  setCurrentPage: (state, currentPage) => {
    state.currentPage = currentPage;
  },
};

const getters = {
  shopProducts: (state) => state.shopProducts,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
