import axiosInstance from "@/axiosInstance";

const state = {
  categories: [],
};

const actions = {
  async fetchCategories({ commit }, params) {
    try {
      const { pageNo = 1, limit = 10, pagination = true } = params;
      const response = await axiosInstance.get(
        `/categories?pageNo=${pageNo}&limit=${limit}&pagination=${pagination}`
      );
      await commit("setCategories", response?.data?.results);
      return;
    } catch (error) {
      console.log("error from category action=====", error);
      return Promise.reject(error);
    }
  },
};

const mutations = {
  setCategories: (state, categories) => {
    state.categories = categories;
  },
};

const getters = {
  categories: (state) => state.categories,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
