<script setup>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <!-- <div class="col-md-4">
        <div class="card card-profile">
        
          <br />
         
          <div class="card-body pt-0">
            <div class="row">
              <div class="col">
                
              </div>
            </div>
            
          </div>
        </div>
      </div> -->
      <div class="col-md-8">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="mb-0">Filter</p>
              <!-- <argon-button color="success" size="sm" class="ms-auto"
                >Settings</argon-button
              > -->
            </div>
          </div>
          <div class="card-body">
            <p class="text-uppercase text-sm">Warehouse Information</p>
            <div class="row">
              <div class="col-md-12">
                <label for="example-text-input" class="form-control-label"
                  >Name</label
                >
                <argon-input type="text" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label for="example-text-input" class="form-control-label"
                  >Address</label
                >
                <argon-input type="text" />
              </div>
            </div>
            <br />
            <!-- <hr class="horizontal dark" /> -->
            <argon-button color="primary" size="sm" class="ms-auto"
              >Save</argon-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
