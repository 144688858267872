<template>
  <argon-pagination>
    <argon-pagination-item
      prev
      :disabled="currentPage === 1"
      @page-click="handlePageClick"
      :pageNo="currentPage"
    />
    <argon-pagination-item
      v-for="page in pages"
      :key="page"
      :label="String(page)"
      :active="page === currentPage"
      :pageNo="currentPage"
      @page-click="handlePageClick"
    />
    <argon-pagination-item
      next
      :disabled="currentPage === totalPages"
      :pageNo="currentPage"
      @page-click="handlePageClick"
    />
  </argon-pagination>
</template>

<script>
import ArgonPagination from "@/components/ArgonPagination.vue";
import ArgonPaginationItem from "@/components/ArgonPaginationItem.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ArgonPagination,
    ArgonPaginationItem,
  },
  props: {
    totalPages: Number,
    currentPage: Number,
  },
  methods: {
    handlePageClick(page) {
      this.$emit("page-changed", page);
    },
    // goToPage(page, totalPage) {
    //   console.log("goToPage=====", page, totalPage);
    //   if (page >= 1 && page <= totalPage) {
    //     this.fetchBrands({ pageNo: page, limit: 10 });
    //   }
    // },
    // ...mapActions(["fetchBrands"]),
  },

  computed: {
    pages() {
      const pages = [];
      const maxPagesToShow = 5;
      const startPage = Math.max(
        1,
        this.currentPage - Math.floor(maxPagesToShow / 2)
      );
      const endPage = Math.min(this.totalPages, startPage + maxPagesToShow - 1);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      return pages;
    },
  },
};
</script>
