<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header pb-0">
          <div class="d-flex align-items-center">
            <p class="mb-0">Add Expense/Income</p>
          </div>
        </div>
        <div class="card-body">
          <p class="text-uppercase text-sm">Add any Expense or Income</p>
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="Note" class="form-control-label">Note</label
                  ><span class="text-sm text-danger">*</span>
                  <input
                    id="Note"
                    class="form-control"
                    type="text"
                    name="note"
                    v-model="entry.note"
                    :class="{ 'is-invalid': formError.note }"
                  />
                  <div class="invalid-feedback">{{ formError?.note }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label for="Transaction" class="form-control-label"
                  >Transaction Type</label
                ><span class="text-sm text-danger">*</span>
                <div class="form-group">
                  <input
                    type="radio"
                    class="btn-check"
                    id="danger-outlined"
                    autocomplete="off"
                    name="transactionType"
                    value="dr"
                    v-model="entry.transactionType"
                  />
                  <label
                    class="btn btn-sm btn-outline-danger"
                    for="danger-outlined"
                    :class="{ 'is-invalid': formError.transactionType }"
                    >Dr.</label
                  >
                  <input
                    type="radio"
                    class="btn-check"
                    id="success-outlined"
                    autocomplete="off"
                    name="transactionType"
                    value="cr"
                    v-model="entry.transactionType"
                  />
                  <label
                    class="btn btn-sm btn-outline-success"
                    for="success-outlined"
                    :class="{ 'is-invalid': formError.transactionType }"
                    >Cr.</label
                  >
                  <div class="invalid-feedback">
                    {{ formError?.transactionType }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label for="Transaction" class="form-control-label"
                  >Category</label
                ><span class="text-sm text-danger">*</span>
                <div class="form-group">
                  <input
                    type="radio"
                    class="btn-check"
                    id="Cash"
                    autocomplete="off"
                    name="tag"
                    value="Cash"
                    v-model="entry.tag"
                  />
                  <label
                    class="btn btn-sm btn-outline-primary"
                    for="Cash"
                    :class="{ 'is-invalid': formError.category }"
                    >Cash</label
                  >
                  <input
                    type="radio"
                    class="btn-check"
                    id="Purchase"
                    autocomplete="off"
                    name="tag"
                    value="Purchase"
                    v-model="entry.tag"
                  />
                  <label
                    class="btn btn-sm btn-outline-primary"
                    for="Purchase"
                    :class="{ 'is-invalid': formError.category }"
                    >Purchase</label
                  >
                  <input
                    type="radio"
                    class="btn-check"
                    id="Sale"
                    autocomplete="off"
                    name="tag"
                    value="Sale"
                    v-model="entry.tag"
                  />
                  <label
                    class="btn btn-sm btn-outline-primary"
                    for="Sale"
                    :class="{ 'is-invalid': formError.category }"
                    >Sale</label
                  >
                  <input
                    type="radio"
                    class="btn-check"
                    id="Salary"
                    autocomplete="off"
                    name="tag"
                    value="Salary"
                    v-model="entry.tag"
                  />
                  <label
                    class="btn btn-sm btn-outline-primary"
                    for="Salary"
                    :class="{ 'is-invalid': formError.category }"
                    >Salary</label
                  >
                  <input
                    type="radio"
                    class="btn-check"
                    id="Fuel"
                    autocomplete="off"
                    name="tag"
                    value="Fuel"
                    v-model="entry.tag"
                  />
                  <label
                    class="btn btn-sm btn-outline-primary"
                    for="Fuel"
                    :class="{ 'is-invalid': formError.category }"
                    >Fuel</label
                  >
                  <input
                    type="radio"
                    class="btn-check"
                    id="Office-Expense"
                    autocomplete="off"
                    name="tag"
                    value="Office Expense"
                    v-model="entry.tag"
                  />
                  <label
                    class="btn btn-sm btn-outline-primary"
                    for="Office-Expense"
                    :class="{ 'is-invalid': formError.category }"
                    >Office Expense</label
                  >
                  <input
                    type="radio"
                    class="btn-check"
                    id="Rent"
                    autocomplete="off"
                    name="tag"
                    value="Rent"
                    v-model="entry.tag"
                  />
                  <label
                    class="btn btn-sm btn-outline-primary"
                    for="Rent"
                    :class="{ 'is-invalid': formError.category }"
                    >Rent</label
                  >
                  <input
                    type="radio"
                    class="btn-check"
                    id="Other"
                    autocomplete="off"
                    name="tag"
                    v-model="entry.tag"
                    value="Other"
                  />
                  <label
                    class="btn btn-sm btn-outline-primary"
                    for="Other"
                    :class="{ 'is-invalid': formError.category }"
                    >Other</label
                  >
                  <div class="invalid-feedback">
                    {{ formError?.category }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="Quantity" class="form-control-label">Date</label
                  ><span class="text-sm text-danger">*</span>
                  <input
                    id="Quantity"
                    class="form-control"
                    type="date"
                    v-model="entry.date"
                    :class="{ 'is-invalid': formError.date }"
                  />
                  <div class="invalid-feedback">{{ formError?.date }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="amount" class="form-control-label">Amount</label
                  ><span class="text-sm text-danger">*</span>
                  <input
                    id="amount"
                    class="form-control"
                    type="number"
                    name="amount"
                    v-model="entry.amount"
                    :class="{ 'is-invalid': formError.amount }"
                  />
                  <div class="invalid-feedback">{{ formError?.amount }}</div>
                </div>
              </div>
            </div>

            <br />
            <argon-button
              type="submit"
              color="primary"
              size="sm"
              class="ms-auto me-2"
              >Save</argon-button
            >
            <argon-button
              @click="cancel"
              type="button"
              color="danger"
              size="sm"
              class="ms-auto"
              >Cancel</argon-button
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    const formError = {};
    const formattedDate = this.formatDate(new Date());
    return {
      entry: {
        note: "",
        transactionType: "",
        tag: "",
        amount: "",
        date: formattedDate,
      },
      formError,
    };
  },
  methods: {
    ...mapActions(["createBalanceSheet"]),
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    errorHandler() {
      this.formError = {};
      let isSubmit = true;
      for (let field in this.entry) {
        if (this.entry[field] === "") {
          if (field === "tag") field = "category";

          const fieldName = field
            .replace(/([a-z0-9])([A-Z])/g, "$1-$2")
            .toLowerCase();
          this.formError[field] = `${fieldName} is required`;
          isSubmit = false;
        }
      }
      isSubmit ? (this.formError = {}) : "";
      return isSubmit;
    },
    async submitForm() {
      if (await this.errorHandler()) {
        try {
          const res = await this.createBalanceSheet(this.entry);
          this.resetForm();
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
            title: res?.data?.message,
            didOpen: (toast) => {
              toast.onmouseenter = this.$swal.stopTimer;
              toast.onmouseleave = this.$swal.resumeTimer;
            },
          });
        } catch (error) {
          this.catchError(error);
        }
      }
    },

    resetForm() {
      this.entry = {
        ...this.entry,
        note: "",
        tag: "",
        transactionType: "",
        amount: "",
      };
    },
    catchError(error) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        icon: "error",
        title: error,
        didOpen: (toast) => {
          toast.onmouseenter = this.$swal.stopTimer;
          toast.onmouseleave = this.$swal.resumeTimer;
        },
      });
    },
    // Redirect to shop-details
    cancel() {
      this.$router.push(`/balance-sheet`);
    },
  },
};
</script>
